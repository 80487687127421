import http from '../utils/http-common'

const getAll = (param) => {
  return http.get(`/receivings`, { params: param })
}

const get = (id) => {
  return http.get(`/receivings/${id}`)
}

const getStatuses = () => {
  return http.get(`/receiving-statuses`)
}

const getDeliveryStatuses = () => {
  return http.get(`/delivery-statuses`)
}

const getReceivingItemReasons = () => {
  return http.get(`/receiving-item-reasons`)
}

const getReceivingItemStatuses = () => {
  return http.get(`/receiving-item-statuses`)
}

const getReceivingItemConditions = () => {
  return http.get(`/receiving-item-conditions`)
}

const getReceivingPOStatuses = () => {
  return http.get(`/receiving-po-statuses`)
}

const getReceivingPOReasons = () => {
  return http.get(`/receiving-po-reasons`)
}

const create = (data) => {
  return http.post(`/receivings`, data)
}

const update = (id, data) => {
  return http.put(`/receivings/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/receivings/${id}`)
}

const deleteItem = (id) => {
  return http.delete(`/receiving-items/${id}`)
}

const getCSVTemplate = () => {
  return http.get(`/import/receiving-structure`)
}

const importCSV = (data) => {
  return http.post(`/import/receivings`, data)
}

const addReceivingItem = (data) => {
  return http.post(`/receiving-items`, data)
}

const modifyReceivingItem = (id, data) => {
  return http.put(`/receiving-items/${id}`, data)
}

const addReceivingMobileItem = (data) => {
  return http.post(`/receiving-items/mobile`, data)
}

const emailExportExcel = (param) => {
  return http.get(`/export/receivings`, { params: param })
}

const uploadImageFile = (id, data) => {
  return http.post(`/receivings-image/${id}`, data)
}

const exportReceivingItems = (param) => {
  return http.get(`/export/receivings-po`, { params: param })
}

const exportReceivingViewItems = (param) => {
  return http.get(`/export/receiving-items/details`, { params: param })
}

// API not working properly
const updateReceivingPurchaseOrder = (id) => {
  return http.put(`/receivings-pos/${id}`)
}

const modifyReceivingItemUomQuantity = (data) => {
  return http.post(`/receiving-item-groups`, data)
}

const modifyReceivingPurchaseOrder = (data) => {
  return http.post(`/receivings/attach/po`, data)
}

const reopenFinalizedReceiving = (id) => {
  return http.post(`/receivings/reopen/${id}`)
}

const modifyBulkItems = (data) => {
  return http.post(`/receivings/modify/item/in-bulk`, data)
}

const approveReceiving = (id) => {
  return http.post(`/receivings/approve/pending-release/${id}`)
}

const closeReceiving = (id) => {
  return http.post(`/receivings/close/pending-release/${id}`)
}

const approveReceivingItemGroups = (data) => {
  return http.post(`/receiving-item-groups/approve/qa-release`, data)
}

const closeReceivingItemGroups = (data) => {
  return http.post(`/receiving-item-groups/close/qa-release`, data)
}

const closeReceivingItems = (data) => {
  return http.post(`/receiving-items/close/pending-release`, data)
}

const addReceivingAttachment = (data) => {
  return http.post(`/receiving-po-documents`, data)
}

const editReceivingAttachment = (id, data) => {
  return http.put(`/receiving-po-documents/${id}`, data)
}

const deleteReceivingAttachment = (id) => {
  return http.delete(`/receiving-po-documents/${id}`)
}

const ReceivingDataService = {
  getAll,
  get,
  remove,
  getStatuses,
  getDeliveryStatuses,
  getReceivingItemReasons,
  getReceivingItemStatuses,
  getReceivingItemConditions,
  getReceivingPOStatuses,
  getReceivingPOReasons,
  create,
  update,
  deleteItem,
  getCSVTemplate,
  importCSV,
  addReceivingItem,
  addReceivingMobileItem,
  emailExportExcel,
  modifyReceivingItem,
  uploadImageFile,
  updateReceivingPurchaseOrder,
  modifyReceivingItemUomQuantity,
  modifyReceivingPurchaseOrder,
  exportReceivingItems,
  reopenFinalizedReceiving,
  modifyBulkItems,
  approveReceiving,
  closeReceiving,
  exportReceivingViewItems,
  approveReceivingItemGroups,
  closeReceivingItemGroups,
  closeReceivingItems,
  addReceivingAttachment,
  deleteReceivingAttachment,
  editReceivingAttachment,
}
export default ReceivingDataService
